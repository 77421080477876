import { environment } from '../../../environment';
import Keycloak from 'keycloak-js';

type KeycloakTokenParsed = any;
export const keycloakInstance = new Keycloak({
  clientId: `${environment.KEYCLOAK_CLIENT_ID}`,
  realm: `${environment.KEYCLOAK_REALM}`,
  url: `${environment.KEYCLOAK_URL}`,
});

export const keycloakInitOptions: any = {
  redirectUri: window.location.href,
  pkceMethod: 'S256',
  onLoad: 'login-required',
  checkLoginIframe: false,
};
