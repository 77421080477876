import { useKeycloak } from '@react-keycloak-fork/web';
import { UserRoles } from '../enums';

export const useAuth = (): any => {
  const { keycloak, initialized } = useKeycloak();

  const tokenParsed = keycloak.tokenParsed;
  const roles = {
    adminSystem: 'admin-system',
    adminTenant: 'admin-tenant',
    adminScenario: 'admin-scenario',
    userScenario: 'user-scenario',
  };
  const isAdminSystem = tokenParsed?.roles.includes(roles.adminSystem);
  const isAdminTenant = tokenParsed?.roles.includes(roles.adminTenant);
  const isAdminScenario = tokenParsed?.roles.includes(roles.adminScenario);

  const defaultUserRole = UserRoles.User;
  const role =
    isAdminSystem || isAdminTenant ? UserRoles.Admin : isAdminScenario ? UserRoles.AdminScenario : defaultUserRole;

  return {
    logout: keycloak.logout,
    token: keycloak.token,
    tokenParsed: keycloak.tokenParsed,
    authenticated: initialized && keycloak.authenticated,
    role,
  };
};
